.creator_collabs_main {
  overflow: auto hidden;
  margin-bottom: 60px;
  // background: red;
  padding: 0;
  @media screen and (max-width: 767px) {
    margin-bottom: 30px;
  }
  .creator_collabs_wrap {
    // background: pink;
    overflow: hidden;
    display: flex;
    // padding: 16px 0;
    gap: 4rem;
    padding: 0 4rem;

    @media screen and (max-width: 767px) {
      gap: 2rem;
      padding: 0 2rem;
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    // margin: 0 16px;

    .creator_collabs_block {
      // background-color: grey;
      aspect-ratio: 9 / 16;
      flex: 1 1;
      border-radius: 1.5rem;
      position: relative;
      min-width: calc(33.33% - 26px);

      @media screen and (max-width: 767px) {
        min-width: calc(75% - 26px);
      }

      // margin-left: 32px;

      // &:nth-last-child(1) {
      //     margin-right: 32px;
      // }

      .creator_collabs_link {
        position: absolute;
        inset: 0px;
        cursor: pointer;
        z-index: 2;
      }

      .creator_collabs_img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        border-radius: 1.5rem;
      }

      .creator_collabs_video_button {
        position: absolute;
        inset: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 1.5rem;
        z-index: 1;

        .creator_collabs_play_btn {
          background-color: transparent;
          border: none;
          color: white;

          svg {
            @media screen and (max-width: 767px) {
              height: 6vh;
            }
          }
        }
      }
    }
  }
}
