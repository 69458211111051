.refresh_ring_main {
  main {
    // padding: 0;
    // background: red;
    background: url("~/public/assets/img/refreshRigns/refreshRingsBg.png");
    background-position: 100% 100%;
    // padding: 0 15rem 0 15rem;
    // margin-top: var(--headerHeight);
    // margin-bottom: 0;

    // @media screen and (max-width: 991px) {
    //   padding: 0 7rem 0 7rem;
    // }

    // @media (max-width: 767px) {
    //   padding: 0;
    // }
    padding: 0 15rem 0 15rem;
    margin-top: var(--headerHeight);
    margin-bottom: 0;

    @media screen and (max-width: 991px) {
      padding: 0 7rem 0 7rem;
    }

    @media (max-width: 767px) {
      padding: 0;
    }

    .firstLoading {
      .loaderImg {
        .loader {
          width: 50px;
          height: 50px;
          border: 5px solid #009639;
          border-top: 5px solid #06c270;
          border-radius: 50%;
          animation: spin 1s linear infinite;
          margin-bottom: 20px;
        }

        color: #06c270;
        text-align: center;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        font-weight: 700;
      }
    }
  }

  .header {
    // display: none;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .mainSlideWrap {
    height: calc(100dvh - var(--headerHeight));
    padding: 5rem 0;
    // display: none !important;

    // overflow: hidden;
    &::-webkit-scrollbar {
      display: none;
      /* For Chrome, Safari, and Opera */
      scrollbar-width: none;
      /* For Firefox */
    }

    @media (max-width: 991px) {
      padding-top: 0;
      padding-bottom: var(--footerHeight);
    }

    @media (max-width: 767px) {
      height: calc(100dvh - var(--headerHeight));
      overflow-x: hidden;
      // overflow-y: auto;
    }

    .slideBlock {
      // aspect-ratio: 9 / 16;
      // border-width: 1px;
      // justify-content: center;
      // align-items: center;
      scroll-snap-align: center;
      // width: 100%;
      // min-height: 100vh;
      display: flex;
      // position: relative;
      min-height: calc(100dvh - var(--headerHeight));
      padding: 5rem 0;
      z-index: 1;

      @media (max-width: 991px) {
        padding-top: 0;
        padding-bottom: 0;
        // min-height: 100dvh;

        // max-width: 48vh;
      }

      &.msg {
      }

      video {
        z-index: 10;
      }

      .videoLoader {
        position: absolute;
        // z-index: -1;
        display: flex;
        min-height: calc(100dvh - var(--headerHeight));
        aspect-ratio: 9 / 16;
        overflow: hidden;
        padding: 5rem 0;
        justify-content: center;

        @media (max-width: 991px) {
          min-height: 100dvh;
          padding: 0;
        }

        .videoLoaderWrap {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-flow: column;
          // background-color: #11a64b;
          aspect-ratio: 9 / 16;
          // width: 100%;
          // z-index: 13;

          .loadingText {
            font-size: 20px;
            font-family: "Aktiv Grotesk";
            font-weight: 600;
          }

          .loader {
            width: 50px;
            height: 50px;
            border: 5px solid rgba(255, 255, 255, 0.3);
            border-top: 5px solid #fff;
            border-radius: 50%;
            animation: spin 1s linear infinite;
          }
        }
      }
    }
  }

  .btn-suprise {
    display: none;

    .btn {
      @media screen and (max-width: 767px) {
        right: inherit;
        left: 0;
      }
    }
  }

  .rrTextMain {
    position: absolute;
    left: 25%;
    top: calc(var(--headerHeight) + 30px);

    @media (max-width: 1920px) {
      left: 20%;
    }

    @media (max-width: 1800px) {
      left: 18%;
    }

    @media (max-width: 1600px) {
      left: 16%;
    }

    @media (max-width: 1400px) {
      left: 13%;
    }

    @media (max-width: 1200px) {
      left: 5%;
    }

    @media (max-width: 992px) {
      display: none;
    }

    .rrTextMainImg {
      width: 20vw;
    }

    .rrTextTitle {
      font-size: 2.5rem;
      font-weight: bold;
    }

    .rrTextSubTitle {
      font-size: 1.7rem;
      font-weight: 400;
    }
  }

  .rightSideButtonsBlock {
    bottom: 10vh;
    // right: 5% !important;

    // @media (min-width: 640px) {
    //     right: -5rem;
    // }
    @media (max-width: 991px) {
      right: 2% !important;
    }

    &:hover {
      background: #fff;
    }

    button {
      &:disabled {
        color: #000;
      }
    }

    @media (max-width: 767px) {
      right: 0rem;
    }

    .imogBtn {
      &.activeReaction {
        scale: 1.1;
        transition: ease all 0.2s;

        .imogBtn_text {
          scale: 1.1;
          transition: ease all 0.2s;
        }
      }

      &.disableReaction {
        opacity: 0.5;
        transition: ease all 0.2s;

        .imogBtn_text {
          opacity: 1;
          transition: ease all 0.2s;
        }
      }

      &:nth-last-child(1) {
        cursor: auto;
      }
    }
  }

  .scanForNewBlock {
    // background-color: #11a64b;
    background-image: url("~/public/assets/img/refreshRigns/scan-bg.png");
    background-position: 100% 100%;
    height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
    // display: none;
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0 -15rem 0 -15rem;

    @media screen and (max-width: 991px) {
      margin: 0;
    }

    .middelBlock {
      margin: 16px;
      background: #fff;
      border-radius: 16px;
      padding: 6dvh 6dvh 8dvh;
      text-align: center;

      .scanNewTopimg {
        margin-bottom: 4dvh;
      }

      .titleForScanNew {
        font-size: 22px;
        font-family: "Aktiv Grotesk Trial";
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0%;
        text-align: center;
      }

      .msgForScanNew {
        font-size: 16px;
        margin-top: 10px;
        max-width: 250px;
      }
    }

    .smilytBottom {
      position: absolute;
      bottom: 10dvh;
      left: 10%;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

.zoomInEmoji {
  animation: zoomIn 0.5s forwards;
}
